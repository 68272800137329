import React, { useState } from "react";
import { Layout, Card, Typography, Divider, Button, message } from "antd";
import { Content } from "antd/es/layout/layout";
import styled from "@emotion/styled";
import { SynonymList } from "components/synonyms/lists/synonymList";
import { PlusOutlined } from "@ant-design/icons";
import useSynonymOpenApi from "apiClients/useSynonymOpenApiClient";
import { CreateSynonymRequest, SynonymDto, UpdateSynonymRequest } from "interfaces/apiTypes";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { SynonymModal } from "components/synonyms/modals/SynonymModal";
import { useLocaleStore } from "stores/localeStore";

const StyledCard = styled(Card)`
  .ant-page-header-heading-left {
    display: none;
  }

  .ant-page-header-heading {
    display: none;
  }
`;

const TableTitle = styled(Typography.Title)`
  margin-bottom: 0.75rem !important;
`;

export const SynonymListPage: React.FC = () => {
  const { getSynonyms, deleteSynonym, updateSynonym, createSynonym } = useSynonymOpenApi();
  const queryClient = useQueryClient();
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedSynonym, setSelectedSynonym] = useState<SynonymDto | null>(null);
  const { activeLanguage } = useLocaleStore();
  
  const { data, isLoading, error } = useQuery({
    queryKey: ['synonyms', activeLanguage?.locale],
    queryFn: () => getSynonyms(),
  });

  const handleDelete = async (synonymId: number) => {
    try {
      await deleteSynonym(synonymId);
      message.success("Synonym borttagen");
      queryClient.invalidateQueries({ queryKey: ['synonyms'] });
    } catch (error) {
      message.error("Kunde inte ta bort synonym");
      console.error(error);
    }
  };

  const showModal = (synonym: SynonymDto | null) => {
    setSelectedSynonym(synonym);
    setModalVisible(true);
  };

  const handleModalCancel = () => {
    setModalVisible(false);
    setSelectedSynonym(null);
  };

  const handleSynonymUpdate = async (id: number, updateRequest: UpdateSynonymRequest) => {
    try {
        await updateSynonym(id, updateRequest);
        message.success("Synonym uppdaterad");
        queryClient.invalidateQueries({ queryKey: ['synonyms'] });
    } catch (error) {
        message.error("Kunde inte uppdatera synonym");
        console.error(error);
    }
  };

  const handleSynonymCreate = async (createRequest: CreateSynonymRequest) => {
    try {
      await createSynonym(createRequest);
      message.success("Synonym skapad");
      queryClient.invalidateQueries({ queryKey: ['synonyms'] });
    } catch (error) {
      message.error("Kunde inte skapa synonym");
      console.error(error);
    }
  };

  return (
    <Layout>
      <Content>
        <StyledCard bordered={false} className="criclebox h-full">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <TableTitle level={4}>Synonymer</TableTitle>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => showModal(null)}
            >
              Lägg till synonym
            </Button>
          </div>
          <Divider
            type="horizontal"
            style={{ height: "auto", margin: "1rem 0", borderWidth: "2px" }}
          />
          <SynonymList
            synonyms={data ?? []}
            onDelete={handleDelete}
            showModal={showModal}
            currentPageSize={20}
            total={data?.length ?? 0}
            loading={isLoading}
          />
          <SynonymModal
            visible={modalVisible}
            onCancel={handleModalCancel}
            synonym={selectedSynonym}
            onSynonymUpdate={handleSynonymUpdate}
            onSynonymCreate={handleSynonymCreate}
          />
        </StyledCard>
      </Content>
    </Layout>
  );
}; 