import React, { useEffect } from 'react';
import { Modal, Form, Input, Button, message } from 'antd';
import { CreateSynonymRequest, SynonymDto, UpdateSynonymRequest } from 'interfaces/apiTypes';

interface SynonymModalProps {
  visible: boolean;
  onCancel: () => void;
  synonym?: SynonymDto | null;
  onSynonymUpdate: (id: number, updateRequest: UpdateSynonymRequest) => void;
  onSynonymCreate: (createRequest: CreateSynonymRequest) => void;
}

export const SynonymModal: React.FC<SynonymModalProps> = ({
  visible,
  onCancel,
  synonym,
  onSynonymUpdate,
  onSynonymCreate,
}) => {
  const [form] = Form.useForm();
  const isEditing = !!synonym?.id;

  useEffect(() => {
    if (visible && synonym) {
      form.setFieldsValue({
        term: synonym.name,
        synonyms: synonym.synonymValues.join(','),
      });
    } else {
      form.resetFields();
    }
  }, [visible, synonym, form]);

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      const synonymsArray = values.synonyms
        .split(',')
        .map((s: string) => s.trim())
        .filter((s: string) => s.length > 0);

      if (isEditing && synonym) {
        onSynonymUpdate(synonym.id, {
          name: values.term,
          synonymValues: synonymsArray,
        });
      } else {
        onSynonymCreate({
          name: values.term,
          synonymValues: synonymsArray,
        });
      }

      onCancel();
    } catch (error) {
      console.error('Error submitting form:', error);
      message.error('Failed to save synonym');
    }
  };

  return (
    <Modal
      title={isEditing ? 'Redigera synonym' : 'Skapa ny synonym'}
      open={visible}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Avbryt
        </Button>,
        <Button key="submit" type="primary" onClick={handleSubmit}>
          {isEditing ? 'Uppdatera' : 'Skapa'}
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        name="synonymForm"
      >
        <Form.Item
          name="term"
          label="Term"
          rules={[{ required: true, message: 'Vänligen ange termen!' }]}
        >
          <Input placeholder="Ange huvudtermen" />
        </Form.Item>
        <Form.Item
          name="synonyms"
          label="Synonymer"
          rules={[{ required: true, message: 'Vänligen ange minst en synonym!' }]}
        >
          <Input.TextArea
            placeholder="Ange synonymer, separerade med kommatecken"
            rows={4}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}; 