import { useState } from "react";
import { List, Typography, Button, Tag, Modal, Spin } from "antd";
import { EditOutlined, DeleteOutlined, ClockCircleOutlined } from "@ant-design/icons";
import { SynonymDto } from "interfaces/apiTypes";

interface SynonymListProps {
  synonyms: SynonymDto[];
  onDelete: (synonymId: number) => void;
  showModal: (synonym: SynonymDto) => void;
  currentPageSize: number;
  total: number;
  loading: boolean;
}

export const SynonymList = ({ synonyms, onDelete, showModal, currentPageSize, total, loading }: SynonymListProps) => {
  if (loading) {
    return (
      <div style={{ 
        display: 'flex', 
        justifyContent: 'center', 
        padding: '48px' 
      }}>
        <Spin size="large" />
      </div>
    );
  }

  const handleDelete = (synonymId: number, term: string) => {
    Modal.confirm({
      title: 'Bekräfta borttagning',
      content: (
        <>
          Är du säker på att du vill ta bort synonymen för "{term}"?
          <br />
          Detta kommer att påverka sökresultaten för denna term.
        </>
      ),
      okText: 'Ja, ta bort',
      cancelText: 'Avbryt',
      okButtonProps: { danger: true },
      onOk: () => onDelete(synonymId),
    });
  };

  return (
    <div style={{ flex: 1, padding: "1rem" }}>
      <List
        className="demo-loadmore-list"
        itemLayout="horizontal"
        dataSource={synonyms}
        style={{ marginBottom: "1rem" }}
        pagination={{
          pageSize: currentPageSize,
          showTotal(total, range) {
            return `Visar ${range[0]}-${range[1]} av ${total} synonymer`;
          },
          total: total,
          style: { textAlign: 'center', marginTop: '1rem' },
          pageSizeOptions: [10, 20, 50, 100],
        }}
        renderItem={(item) => (
          <List.Item
            style={{
              border: "1px solid #e4e4e7",
              borderRadius: "0.5rem",
              marginBottom: "1rem",
              position: "relative",
            }}
          >
            <List.Item.Meta
              title={
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', marginBottom: '0.5rem' }}>
                  <Typography.Title
                    level={5}
                    style={{ margin: 0, fontWeight: 600 }}
                  >
                    {item.name}
                  </Typography.Title>
                  <Typography.Text type="secondary" style={{ fontSize: '0.9em', color: "#000", display: 'flex', alignItems: 'center', gap: '5px' }}>
                    {new Date(item.createdAt ?? new Date()).toLocaleDateString("sv-SE")}
                    <ClockCircleOutlined style={{ fontSize: '0.9rem', marginBottom: '4px' }}/>
                  </Typography.Text>
                </div>
              }
              style={{ padding: "1rem", width: '100%' }}
              description={
                <div>
                  <div style={{ display: "flex", gap: "0.5rem", flexWrap: "wrap", marginBottom: "0.5rem" }}>
                    <p style={{ margin: 0 }}>Synonymer:</p>
                    <div style={{ display: "flex", gap: "0.25rem", flexWrap: "wrap" }}>
                      {item.synonymValues.map((synonym, index) => (
                        <Tag key={index} color="blue">{synonym}</Tag>
                      ))}
                    </div>
                  </div>
                  <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                    <div style={{ display: "flex", gap: "0.5rem" }}>
                      <Button
                        type="text"
                        icon={<EditOutlined />}
                        onClick={() => showModal(item)}
                      >
                        Redigera
                      </Button>
                      <Button
                        type="text"
                        danger
                        icon={<DeleteOutlined />}
                        onClick={() => handleDelete(item.id ?? 0, item.name)}
                      >
                        Ta bort
                      </Button>
                    </div>
                  </div>
                </div>
              }
            />
          </List.Item>
        )}
      />
    </div>
  );
}; 