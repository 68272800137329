import { HttpError, useApiUrl, useList } from "@refinedev/core";
import { CATEGORY_RES_ID } from '../../../utility/constants';
import React, { useEffect, useState } from 'react';
import { Tree } from 'antd';
import type { ICategory } from "interfaces";
import { fetchSubCategories } from "../productCategories/productCategoriesApiService";
import {  useProductCategoriesSelectContext } from "components/common/productCategories/context/productCategoriesContext";
import { Spin } from "antd";
import styled from '@emotion/styled'
import { DataNode, AntTreeNodeProps } from 'antd/lib/tree';
import { useLocaleStore } from "stores/localeStore";


const TreeWrapper = styled.div`
  min-height: 18rem;
  .request-indicator-container {
    min-height: 18rem;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F7F8F9 !important;
    border-radius: 6px;
    color: rgba(0, 0, 0, 0.88);
  }
`

interface ProductCategoriesSelectProps {
  categories?: ICategory[];
}


// It's just a simple demo. You can use tree map to optimize update perf.
const updateTreeData = (list: DataNode[], key: React.Key, children: DataNode[]): DataNode[] =>
  list.map((node) => {
    if (node.key === key) {
      return {
        ...node,
        children,
      };
    }
    if (node.children) {
      return {
        ...node,
        children: updateTreeData(node.children, key, children),
      };
    }
    return node;
  });


const CategoriesTreeSelect: React.FC<ProductCategoriesSelectProps> = () => {
  const apiUrl = useApiUrl();
  const localeState = useLocaleStore();
  const [ treeData, setTreeData ] = useState<DataNode[] | []>([]);
  const { selectedCategoryNodes, addCategoryNode, deleteCategoryNode } = useProductCategoriesSelectContext();
  const checkedKeys = selectedCategoryNodes?.map((node: any) => node?.key) ?? [];

  const { data, isLoading, isFetching, isError } = useList<ICategory, HttpError>({
    resource: CATEGORY_RES_ID,
    pagination: {
      pageSize: 100,
    },
    filters: [
      {
        field: "level",
        operator: "eq",
        value: 1,
      },
    ],
    meta: { headers: { "lang": localeState.locale } },
  });


  const onCheck = (_: React.Key[] | { checked: React.Key[]; halfChecked: React.Key[] }, e: AntTreeNodeProps) => {
    if (!e.node || !e.node.key || e.checked === undefined) {
      console.warn("No node key found in onCheck event.");
      return;
    }
    if (e.checked === false) {

      deleteCategoryNode(e.node.key);

    } else if (e.checked === true) {

      const newCategoryNode = {
        title: `${e.node?.title}`, 
        key: e.node.key,
      };
      
      addCategoryNode(newCategoryNode);

    }
  };
  
  // Set the treeData when the data is loaded
  useEffect(() => {
    const categoryData = data?.data;

    if (!Array.isArray(categoryData)) {
      return
    }
    const treeData = categoryData.map((category) => {
      return {
        title: `${category?.name} (${category?.externalId})`, 
        key: category?.id,
        disabled: category?.isHidden,
        isLeaf: false
      }
    })

    setTreeData(treeData)
  
  }, [data?.data]);

  // Update the treeData when subcategories are loaded
  const onLoadData = async ({ key, children }: any) => {
    if (children) {
      // No need to fetch when children exist
      return;
    }
    try {
      // TO DO: Error handling on failed fetch
      const subCategories = await fetchSubCategories(apiUrl, key, localeState.locale);

      const newNodes = subCategories?.map((subCategory) => ({
        title: `${subCategory?.name} (${subCategory?.externalId})`, 
        key: subCategory?.id, 
        isLeaf: !(subCategory?.hasSubcategories ?? false),
        disabled: subCategory?.isHidden,
      }));

      setTreeData((origin) => updateTreeData(origin, key, newNodes));

    } catch (error) {
      console.error('Failed to load subcategories:', error);
    }
  };

  const gotTreeData = Array.isArray(treeData) && treeData.length > 0;

  return (
    <TreeWrapper>
      {/* Loading State: Show spinner only if loading and data hasn't been loaded yet */}
      {isLoading && !gotTreeData && (
        <div className="request-indicator-container">
          <Spin />
        </div>
      )}
  
      {/* Error State: Show error message if it's an error state and not loading */}
      {isError && !isLoading && (
        <div className="request-indicator-container">
          Failed to load data
        </div>
      )}
  
      {/* Always rendered */}
      <Tree style={{minHeight: "18rem"}} loadData={onLoadData} treeData={treeData} checkable={true} onCheck={onCheck} checkStrictly={true} checkedKeys={checkedKeys} />
    </TreeWrapper>
  );
};

export default CategoriesTreeSelect;