import { useState } from "react";
import { List, Typography, Button, Tag, Modal } from "antd";
import { EditOutlined, DeleteOutlined, ClockCircleOutlined } from "@ant-design/icons";
import { getRuleTypeDisplayName, ProductRuleDto, RuleTypeEnum } from "interfaces/apiTypes";

interface RuleListProps {
  rules: ProductRuleDto[];
  onDelete: (ruleId: number) => void;
  showModal: (rule: ProductRuleDto) => void;
  onPageChange: (page: number, pageSize: number) => void;
  currentPageSize: number;
  total: number;
}

export const RuleList = ({ rules, onDelete, showModal, onPageChange, currentPageSize, total }: RuleListProps) => {
  if (!rules) {
    return <div>Loading...</div>;
  }

  const handleDelete = (ruleId: number, ruleName: string) => {
    Modal.confirm({
      title: 'Bekräfta borttagning',
      content: (
        <>
          Är du säker på att du vill ta bort regeln "{ruleName}"?
          <br />
          Reglerna kommer att uppdateras för alla produkter som matchar denna regel.
        </>
      ),
      okText: 'Ja, ta bort',
      cancelText: 'Avbryt',
      okButtonProps: { danger: true },
      onOk: () => onDelete(ruleId),
    });
  };

  return (
    <div style={{ flex: 1, padding: "1rem" }}>
      <List
        className="demo-loadmore-list"
        itemLayout="horizontal"
        dataSource={rules}
        style={{ marginBottom: "1rem" }}
        pagination={{
          pageSize: currentPageSize,
          showTotal(total, range) {
            return `Visar ${range[0]}-${range[1]} av ${total} regler`;
          },
          total: total,
          style: { textAlign: 'center', marginTop: '1rem' },
          onChange: (page, pageSize) => {
            onPageChange(page, pageSize);
          }
        }}
        renderItem={(item) => (
          <List.Item
            style={{
              border: "1px solid #e4e4e7",
              borderRadius: "0.5rem",
              marginBottom: "1rem",
              position: "relative",
            }}
          >
            <List.Item.Meta
              title={
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', marginBottom: '0.5rem' }}>
                  <Typography.Title
                    level={5}
                    style={{ margin: 0, fontWeight: 600 }}
                  >
                    {item.name}
                  </Typography.Title>
                  <Typography.Text type="secondary" style={{ fontSize: '0.9em', color: "#000", display: 'flex', alignItems: 'center', gap: '5px' }}>
                    {new Date(item.createdAt ?? new Date()).toLocaleDateString("sv-SE")}
                    <ClockCircleOutlined style={{ fontSize: '0.9rem', marginBottom: '4px' }}/>
                  </Typography.Text>
                </div>
              }
              style={{ padding: "1rem", width: '100%' }}
              description={
                <div>
                  <span style={{ marginBottom: "0.5rem", display: "block" }}>Typ: <Tag>{getRuleTypeDisplayName(Number(item.ruleType))}</Tag></span>
                  <div style={{ display: "flex", gap: "0.5rem", marginBottom: "0rem" }}><span style={{marginBottom: "0.5rem"}}>Regelvärde:</span><div><Tag color="blue">{item.expr}</Tag></div></div>
                  {item.excludeExpr && (
                    <div style={{ display: "flex", gap: "0.5rem", marginTop: "0" }}><span style={{marginBottom: "0.5rem"}}>Exkludera kategorier:</span><div><Tag color="red">{item.excludeExpr}</Tag></div></div>
                  )}
                  <p style={{ marginBottom: "0rem" }}>
                    Multipliceringsfaktor: <strong>{item.multiplier}</strong>
                  </p>
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <p style={{ marginBottom: "0rem" }}>
                        Aktiv: <span style={{ color: "#000", marginLeft: "0.5rem" }}>{new Date(item.startDate ?? "").toLocaleDateString("sv-SE")} - {new Date(item.endDate ?? "").toLocaleDateString("sv-SE")}</span>
                    </p>
                    <div style={{ marginTop: "0.5rem", display: "flex", justifyContent: "flex-end", gap: "0.5rem" }}>
                    <Button
                      type="text"
                      icon={<EditOutlined />}
                      onClick={() => showModal(item)}
                    >
                      Redigera
                    </Button>
                    <Button
                      type="text"
                      danger
                      icon={<DeleteOutlined />}
                      onClick={() => handleDelete(item.id ?? 0, item.name)}
                    >
                      Ta bort
                    </Button>
                  </div>
                  </div>
                </div>
              }
            />
          </List.Item>
        )}
      />
    </div>
  );
};