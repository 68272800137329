import { List, Typography, Tag, Spin } from 'antd';
import { ProductScoreDto } from 'interfaces/apiTypes';
import { PictureOutlined, FireOutlined } from '@ant-design/icons';

interface ProductRankingListProps {
  items: ProductScoreDto[];
  loading?: boolean;
}

export const ProductRankingList = ({ items, loading }: ProductRankingListProps) => (
    <div style={{ flex: 1, padding: '1rem' }}>
      <Spin spinning={loading}>
        <List
          itemLayout="horizontal"
          dataSource={items}
          renderItem={(item) => (
            <List.Item style={{ border: '1px solid #e4e4e7', borderRadius: '0.5rem', marginBottom: '1rem' }}>
              <List.Item.Meta
                avatar={
                  item.imageUrl ? (
                    <img 
                      src={item.imageUrl}
                      alt={item.name}
                      style={{ 
                        width: '120px',
                        height: '120px',
                        objectFit: 'contain',
                        borderRadius: '0.25rem'
                      }}
                    />
                  ) : (
                    <div style={{ 
                      width: '120px',
                      height: '120px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      background: '#f5f5f5',
                      borderRadius: '0.25rem'
                    }}>
                      <PictureOutlined style={{ fontSize: '2rem', color: '#999' }} />
                    </div>
                  )
                }
                style={{ padding: '1rem' }}
                description={
                  <div style={{ padding: '0rem 1rem' }}>
                      <div style={{marginBottom: '0.5rem'}}>
                          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                              <Typography.Title level={5} style={{ margin: 0, fontWeight: 600 }}>{item.name}</Typography.Title>
                              <Typography.Text style={{fontSize: '0.8rem', fontWeight: 600}}>{new Date(item.lastCalculation).toLocaleString('sv-SE')}</Typography.Text>
                          </div>
                          <Typography.Text>{item.mainItemNo}</Typography.Text>
                      </div>
                      
                      <div style={{display: 'flex', flexDirection: 'row', marginBottom: '0.25rem'}}>
                          <Typography.Text style={{marginRight: '0.5rem'}}>Baspoäng: </Typography.Text><Tag>{item.basePopularityScore}</Tag>
                      </div>

                      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '0.25rem'}}>
                          <Typography.Text style={{marginRight: '0.5rem'}}>Rankingpoäng: </Typography.Text>
                          <Tag>{item.currentPopularityScore}<FireOutlined style={{ marginLeft: '4px', color: '#ff4d4f' }} /></Tag>
                      </div>

                      <div style={{display: 'flex', flexDirection: 'row'}}>
                          <Typography.Text style={{marginRight: '0.5rem'}}>Multipliceringsfaktor: </Typography.Text><Tag>{item.totalBoostMultiplier}x</Tag>
                      </div>
                  </div>
                }
              />
            </List.Item>
          )}
        />
      </Spin>
    </div>
  )