import { useEffect, useRef, useState } from 'react';
import { useOpenApiClient } from './base/useOpenApiClient';
import { ErrorResponse, GetManyRequestOptions } from 'interfaces/baseTypes';
import { ProductRuleDto, ProductRuleCreateRequest, ProductRuleUpdateRequest, ProductRuleDtoPaginationResult, ProductScoreDtoPaginationResult } from 'interfaces/apiTypes';

const useProductRuleOpenApi = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<ErrorResponse | null>(null);
    const {client, getClient} = useOpenApiClient();
    const clientRef = useRef(client);

    useEffect(() => {
        clientRef.current = client;
    }, [client]);

    async function getProductRules(options: GetManyRequestOptions = {}): Promise<ProductRuleDtoPaginationResult> {
        const { page = 1, pageSize = 10 } = options;
        setLoading(true);
        setErrorMessage(null);
        try {
            if(!clientRef.current){
                clientRef.current = getClient();
            }
            
            if (!clientRef.current) {
                throw new Error('API client is not initialized');
            }
            
            const response = await clientRef.current.GET('/api/product-rules', {
                params: {
                    query: {
                        page,
                        pageSize,
                    }
                }
            });

            const error = response?.error as unknown as ErrorResponse;
            if (error) {
                setErrorMessage(error);
                return { data: [], total: 0 };
            }

            return response?.data as ProductRuleDtoPaginationResult;
        } catch (e: any) {
            setErrorMessage(e.message || 'An error occurred');
            return { data: [], total: 0 };
        } finally {
            setLoading(false);
        }
    }

    async function createProductRule(rule: ProductRuleCreateRequest): Promise<ProductRuleDto | undefined> {
        setLoading(true);
        setErrorMessage(null);
        try {
            if(!clientRef.current){
                clientRef.current = getClient();
            }

            if (!clientRef.current) {
                throw new Error('API client is not initialized');
            }
            
            const response = await clientRef.current.POST('/api/product-rules', {
                body: rule,
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            const error = response?.error as unknown as ErrorResponse;
            if (error) {
                setErrorMessage(error);
                return undefined;
            }

            return response?.data as ProductRuleDto;
        } catch (e: any) {
            setErrorMessage(e.message || 'An error occurred');
            return undefined;
        } finally {
            setLoading(false);
        }
    }

    async function updateProductRule(id: number, rule: ProductRuleUpdateRequest): Promise<ProductRuleDto | undefined> {
        setLoading(true);
        setErrorMessage(null);
        try {
            if(!clientRef.current){
                clientRef.current = getClient();
            }
            
            if (!clientRef.current) {
                throw new Error('API client is not initialized');
            }

            const response = await clientRef.current.PUT('/api/product-rules/{id}', {
                params: { path: { id } },
                body: rule,
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            const error = response?.error as unknown as ErrorResponse;
            if (error) {
                setErrorMessage(error);
                return undefined;
            }

            return response?.data as ProductRuleDto;
        } catch (e: any) {
            setErrorMessage(e.message || 'An error occurred');
            return undefined;
        } finally {
            setLoading(false);
        }
    }

    async function deleteProductRule(id: number): Promise<void> {
        setLoading(true);
        setErrorMessage(null);
        try {
            if(!clientRef.current){
                clientRef.current = getClient();
            }
            
            if (!clientRef.current) {
                throw new Error('API client is not initialized');
            }

            const response = await clientRef.current.DELETE('/api/product-rules/{id}', {
                params: { path: { id } }
            });

            const error = response?.error as unknown as ErrorResponse;
            if (error) {
                setErrorMessage(error);
                return undefined;
            }
        } catch (e: any) {
            setErrorMessage(e.message || 'An error occurred');
            return undefined;
        } finally {
            setLoading(false);
        }
    }

    async function recalculateProductRules(): Promise<void> {
        setLoading(true);
        setErrorMessage(null);
        try {
            if(!clientRef.current){
                clientRef.current = getClient();
            }
            
            if (!clientRef.current) {
                throw new Error('API client is not initialized');
            }

            const response = await clientRef.current.PUT('/api/product-rules/recalculate');

            const error = response?.error as unknown as ErrorResponse;
            if (error) {
                setErrorMessage(error);
                return undefined;
            }
        } catch (e: any) {
            setErrorMessage(e.message || 'An error occurred');
            return undefined;
        } finally {
            setLoading(false);
        }
    }

    async function getProductRankings(options: GetManyRequestOptions = {}): Promise<ProductScoreDtoPaginationResult> {
        const { page = 1, pageSize = 10, search = ""} = options;
        setLoading(true);
        setErrorMessage(null);
        try {
            if(!clientRef.current){
                clientRef.current = getClient();
            }

            if (!clientRef.current) {
                throw new Error('API client is not initialized');
            }

            const response = await clientRef.current.GET('/api/product/rankings', {
                params: {
                    query: {
                        page,
                        pageSize,
                        searchTerm: search
                    }
                }
            });

            const error = response?.error as unknown as ErrorResponse;
            if (error) {
                setErrorMessage(error);
                return { data: [], total: 0 };
            }

            return response?.data as ProductScoreDtoPaginationResult;
        }
        catch (e: any) {
            setErrorMessage(e.message || 'An error occurred');
            return { data: [], total: 0 };
        } finally {
            setLoading(false);
        }
    }

    return { 
        getProductRules, 
        createProductRule, 
        updateProductRule, 
        deleteProductRule,
        recalculateProductRules,
        getProductRankings,
        loading, 
        errorMessage 
    };
}

export default useProductRuleOpenApi;