import { ECOMMERCE_RES_ID, FEEDBACK_RES_ID, PRICE_CHECK_RES_ID, PROMO_CARDS_RES_ID, TENANT_RES_ID, USERS_RES_ID } from 'utility/constants';
import { getUserInfoFromCache } from 'utility/helper';

export const customAccessControlProvider = {
  can: async ({ resource, action, params }: any) => {
    const user = getUserInfoFromCache();
    var roles = user?.role.split(",").map(String);
    if (roles.includes("SuperAdmin")) {
        return { can: true };
    } else {
      if(roles.includes("Admin")){
        if (resource == USERS_RES_ID || resource == TENANT_RES_ID) {
            return {
                can: false,
                reason: "Unauthorized",
            };
        }
      }else{
        if (resource == USERS_RES_ID || resource == TENANT_RES_ID || resource == FEEDBACK_RES_ID || resource == PROMO_CARDS_RES_ID || resource == PRICE_CHECK_RES_ID) {
          return {
              can: false,
              reason: "Unauthorized",
          };
        }
      }
    }
    return { can: true };
  },
};
