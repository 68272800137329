import type React from "react"
import { useState, useEffect } from "react"
import { Layout, List, Button, Modal, Form, Input, Select, Divider, Typography, Card, Tag } from "antd"
import { PlusOutlined, EditOutlined, DeleteOutlined, SaveOutlined, CalculatorOutlined } from "@ant-design/icons"
import styled from "@emotion/styled"
import { RuleList } from "components/feedback/lists/ruleList"
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import useProductRuleOpenApi from "apiClients/useProductRuleOpenApiClient";
import { getRuleTypeDisplayName, ProductRuleDto, RuleTypeEnum } from "interfaces/apiTypes"
import FullScreenLoader from "components/common/loadIndicators/fullScreenLoader";
import { ProductRankingList } from "components/feedback/lists/productRankingList"
import { useDebounce } from "components/hooks/useDebounce"

const { Content } = Layout
const { Option } = Select

interface Product {
  id: number
  name: string
  description: string
  tags: string[]
  timestamp: string
}

const StyledCard = styled(Card)`
  .ant-page-header-heading-left {
    display: none;
  }

  .ant-page-header-heading {
    display: none;
  }
`;

const TableTitle = styled(Typography.Title)`
  margin-bottom: 0.75rem !important;
`;

export const FeedbackList: React.FC = () => {
  const [rules, setRules] = useState<ProductRuleDto[]>([])
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentPageSize, setCurrentPageSize] = useState<number>(10);
  const [selectedRuleType, setSelectedRuleType] = useState<RuleTypeEnum | undefined>(undefined);
  const [searchInput, setSearchInput] = useState<string>("");
  const debouncedSearchTerm = useDebounce(searchInput, 200); 

  const [products, setProducts] = useState<Product[]>([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [editingRule, setEditingRule] = useState<ProductRuleDto | null>(null)
  const [form] = Form.useForm()

  const { getProductRules, createProductRule, updateProductRule, deleteProductRule, recalculateProductRules, getProductRankings } = useProductRuleOpenApi();

  const queryClient = useQueryClient();

  const {
    data: apiRulesPaginationResult,
    isLoading,
    isError
} = useQuery(['productRules', 'page', currentPage, 'pageSize', currentPageSize], () => getProductRules({ page: currentPage, pageSize: currentPageSize }));

  const {
    data: apiProductRankings,
    isLoading: isLoadingProductRankings,
    isFetching: isFetchingProductRankings,
    isError: isErrorProductRankings
  } = useQuery(['productRankings', 'page', currentPage, 'pageSize', currentPageSize, "search", debouncedSearchTerm ], () => getProductRankings({ 
    page: currentPage, 
    pageSize: 10, 
    search: debouncedSearchTerm 
  }));

  const createMutation = useMutation({
    mutationFn: createProductRule,
    onSuccess: () => {
      queryClient.invalidateQueries(['productRules', 'page', currentPage, 'pageSize', currentPageSize]);
    },
  });

  const recalculateMutation = useMutation({
    mutationFn: recalculateProductRules,
    onSuccess: () => {
      queryClient.invalidateQueries(['productRules', 'page', currentPage, 'pageSize', currentPageSize]);
    },
  });

  const updateMutation = useMutation({
    mutationFn: (rule: ProductRuleDto) => updateProductRule(rule.id ?? 0, rule),
    onSuccess: () => {
      queryClient.invalidateQueries(['productRules', 'page', currentPage, 'pageSize', currentPageSize]);
    },
  });

  const deleteMutation = useMutation({
    mutationFn: (ruleId: number) => deleteProductRule(ruleId),
    onSuccess: () => {
      queryClient.invalidateQueries(['productRules', 'page', currentPage, 'pageSize', currentPageSize]);
    },
  });

  useEffect(() => {
    if (apiRulesPaginationResult) {
      setRules(apiRulesPaginationResult.data ?? []);
    }
  }, [apiRulesPaginationResult]);

  const handleDelete = (ruleId: number) => {
    deleteMutation.mutate(ruleId);
  }

  const showModal = (rule?: ProductRuleDto) => {
    if (rule) {
      setEditingRule(rule)
      form.setFieldsValue({
        ...rule,
        ruleType: rule.ruleType,
        startDate: rule.startDate ? new Date(rule.startDate).toISOString().split('T')[0] : undefined,
        endDate: rule.endDate ? new Date(rule.endDate).toISOString().split('T')[0] : undefined
      })
    } else {
      setEditingRule(null)
      form.resetFields()
      // Set default dates for new rules
      const today = new Date()
      const nextYear = new Date()
      nextYear.setFullYear(today.getFullYear() + 1)
      
      form.setFieldsValue({
        startDate: new Date(today.getTime() - (2 * 24 * 60 * 60 * 1000)).toISOString().split('T')[0],
        endDate: nextYear.toISOString().split('T')[0],
        ruleType: RuleTypeEnum.Brand,
        multiplier: 2.0,
      })
    }
    setIsModalVisible(true)
  }

  const handleModalOk = () => {
    form.validateFields()
      .then((values) => {
        const newRule: ProductRuleDto = {
          id: editingRule?.id || Date.now(),
          ...values,
          ruleType: Number(values.ruleType),
          startDate: values.startDate ? new Date(values.startDate).toISOString() : null,
          endDate: values.endDate ? new Date(values.endDate).toISOString() : null,
          excludeExpr: values.excludeExpr || null,
        }

        if (editingRule) {
          updateMutation.mutate(newRule);
        } else {
          createMutation.mutate(newRule);
        }

        setIsModalVisible(false);
        form.resetFields();
      })
      .catch((error) => {
        console.error('Validation failed:', error);
      });
  }

  const handleModalCancel = () => {
    setIsModalVisible(false)
    form.resetFields()
  }

  const handlePageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setCurrentPageSize(pageSize);
  }

  const handleRecalculateRules = () => {
    Modal.confirm({
      title: 'Är du säker?',
      content: 'Vill du verkligen räkna om alla regler?',
      okText: 'Ja',
      cancelText: 'Avbryt',
      onOk: () => {
        recalculateMutation.mutate();
      },
    });
  };

  // Get isLoading states from all mutations and queries
  const isLoadingInitial = isLoading;
  const isLoadingCreate = createMutation.isLoading;
  const isLoadingUpdate = updateMutation.isLoading;
  const isLoadingDelete = deleteMutation.isLoading;
  const isLoadingRecalculate = recalculateMutation.isLoading;

  // Combine all loading states
  const isLoadingAny = isLoadingInitial || 
                     isLoadingCreate || 
                     isLoadingUpdate || 
                     isLoadingDelete || 
                     isLoadingRecalculate;

  // Get appropriate loading message based on which operation is in progress
  const getLoadingMessage = () => {
    if (isLoadingCreate) return "Skapar regel...";
    if (isLoadingUpdate) return "Uppdaterar regel...";
    if (isLoadingDelete) return "Tar bort regel...";
    if (isLoadingRecalculate) return "Räknar om alla regler...";
    return "Laddar produktregler...";
  };

  const getExprPlaceholder = (ruleType?: RuleTypeEnum) => {
    switch (ruleType) {
      case RuleTypeEnum.Brand:
        return "pela,ryobi";
      case RuleTypeEnum.Category:
        return "1023,1024,1025";
      case RuleTypeEnum.CategoryCampaign:
        return "1023,1024,1025";
      case RuleTypeEnum.IndividualProduct:
        return "492059,543628";
      case RuleTypeEnum.AllCampaignProducts:
        return "Skriv något, gäller för alla kampanjprodukter";
      case RuleTypeEnum.AllNewsProducts:
        return "Skriv något, gäller för alla skapade produkter yngre än 60 dagar";
      default:
        return "pela,ryobi";
    }
  };

  return (
    <Layout>
      <Content>
        <>
          <FullScreenLoader 
            loading={isLoadingAny} 
            message={getLoadingMessage()} 
          />
          {!isLoadingInitial && (
            <StyledCard bordered={false} className="criclebox h-full">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <TableTitle level={4}>Produkt Ranking</TableTitle>
                <div style={{ display: "flex", gap: "1rem" }}>
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => showModal()}
                  >
                    Lägg till ny regel
                  </Button>
                  <Button
                    type="primary"
                    icon={<CalculatorOutlined />}
                    onClick={handleRecalculateRules}
                  >
                    Räkna om alla regler
                  </Button>
                </div>
              </div>

              <Divider
                type="horizontal"
                style={{ height: "auto", margin: "1rem 0", borderWidth: "2px" }}
              />
              <div style={{ display: "flex", flexDirection: "row" }}>
                <RuleList
                  rules={rules}
                  onDelete={handleDelete}
                  showModal={showModal}
                  onPageChange={handlePageChange}
                  currentPageSize={currentPageSize}
                  total={apiRulesPaginationResult?.total ?? 0}
                />
                <Divider
                  type="vertical"
                  style={{ height: "auto", borderWidth: "2px" }}
                />
                <div style={{ flex: 1 }}>
                  <div style={{ padding: "1rem 1rem 0 1rem" }}>
                    <Input.Search
                      placeholder="Sök efter produkt..."
                      style={{ width: "100%" }}
                      allowClear
                      onChange={(e) => setSearchInput(e.target.value)}
                    />
                  </div>
                  <ProductRankingList 
                    items={apiProductRankings?.data ?? []} 
                    loading={isFetchingProductRankings}
                  />
                </div>
              </div>
            </StyledCard>
          )}
        </>

        <Modal
          title={editingRule ? "Redigera regel" : "Lägg till ny regel"}
          open={isModalVisible}
          onOk={handleModalOk}
          onCancel={handleModalCancel}
        >
          <Form form={form} layout="vertical">
            <Form.Item
              name="name"
              label="Namn"
              rules={[
                { required: true, message: "Ange namn!" },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="ruleType"
              label="Typ"
              rules={[
                { required: true, message: "Välj typ!" },
              ]}
              normalize={(value) => {
                if (value === undefined || value === null) return undefined;
                return Number.parseInt(value, 10);
              }}
            >
              <Select onChange={(value) => setSelectedRuleType(Number(value))}>
                {Object.values(RuleTypeEnum)
                  .filter(value => !isNaN(Number(value)))
                  .map(value => (
                    <Option key={value} value={value}>
                      {getRuleTypeDisplayName(Number(value))}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="expr"
              label="Regelvärde"
              rules={[
                { required: true, message: "Ange regelvärde!" },
              ]}
            >
              <Input.TextArea 
                rows={3} 
                placeholder={getExprPlaceholder(selectedRuleType)}
              />
            </Form.Item>
            <Form.Item
              name="excludeExpr"
              label="Exkludera kategorier"
              rules={[
                { required: false },
              ]}
            >
              <Input.TextArea 
                rows={3} 
                placeholder="Ange kategorier som regeln inte ska gälla för, separerade med kommatecken"
              />
            </Form.Item>
            <Form.Item
              name="multiplier"
              label="Multipliceringsfaktor"
              normalize={(value) => {
                // Only parse if there's a value and it's not in the middle of typing a decimal
                if (value && !value.toString().endsWith(".")) {
                  return parseFloat(value);
                }
                return value;
              }}
              rules={[
                { required: true, message: "Ange multipliceringsfaktor!" },
              ]}
            >
              <Input
                type="number"
                step="0.1"
                min="0"
                placeholder="Ange multipliceringsfaktor (t.ex. 1.2)"
              />
            </Form.Item>
            <Form.Item
              name="startDate"
              label="Startdatum"
              rules={[
                { required: true, message: "Välj startdatum!" },
              ]}
            >
              <Input type="date" />
            </Form.Item>
            <Form.Item
              name="endDate"
              label="Slutdatum"
              rules={[
                { required: true, message: "Välj slutdatum!" },
              ]}
            >
              <Input type="date" />
            </Form.Item>
          </Form>
        </Modal>
      </Content>
    </Layout>
  );
}