import { useEffect, useState } from 'react';
import { useLocaleStore } from 'stores/localeStore';
import { getUserInfoFromCache } from 'utility/helper';
import type { paths } from './schema.d.ts';
import createClient, { FetchOptions } from 'openapi-fetch';

const BASE_URL = process.env.REACT_APP_OPEN_API_URL;

type ApiClient = ReturnType<typeof createClient<paths>>;

export const useOpenApiClient = () => {
    const [client, setClient] = useState<ApiClient>();
    const { activeLanguage } = useLocaleStore();
    const userInfo = getUserInfoFromCache();

    const getClient = () => {
        const options: FetchOptions<paths> = {
            baseUrl: BASE_URL,
            headers: {
              'Authorization': `Bearer ${userInfo.token}`,
            },
        };
        const client = createClient<paths>(options);   
        return client;
    }

    useEffect(() => {
        if (!client) {
            const client = getClient();
            setClient(client);
        }
    }, [activeLanguage?.locale]);

    return { client, getClient };
};