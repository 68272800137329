import {
    Refine,
    Authenticated,
    IResourceItem,
    Action,
    CanAccess,
    I18nProvider
} from "@refinedev/core";
import {
    notificationProvider,
    ThemedLayoutV2,
    ErrorComponent,
    AuthPage,
    RefineThemes,
} from "@refinedev/antd";
import { Gauge, UsersRound, Landmark, PackageSearch, List, Tags, FileSearch, Rocket, ChartGantt, ListCheck, CircleDollarSign, Store, ShoppingCart, Users2, Package, Layers, Box, MessageSquare, Megaphone } from 'lucide-react';
import { useTranslation } from "react-i18next";
import routerProvider, {
    NavigateToResource,
    CatchAllNavigate,
    UnsavedChangesNotifier,
    DocumentTitleHandler,
} from "@refinedev/react-router-v6";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import { ConfigProvider } from "antd";

import "@refinedev/antd/dist/reset.css";

import { DashboardPage } from "pages/dashboard";
import { Login } from "pages/login";
import { FILE_RES_ID, PRODUCT_ITEM_RES_ID, PRODUCT_RES_ID, SPECIFICATION_LABEL_RES_ID, TENANT_RES_ID, USERS_RES_ID, PROMO_CARDS_RES_ID, FEEDBACK_RES_ID, PRICE_CHECK_RES_ID, SYNONYM_RES_ID } from "utility/constants";
import { Header } from "components/layout/header";
import sv_SE from 'antd/es/locale/sv_SE';
import { dataProvider } from "./rest-data-provider"; 
import { authProvider } from "auth-provider";
import { customAccessControlProvider } from "access-control-provider";
import { UsersList } from "pages/users/list";
import { UserCreate } from "pages/users/create";
import { UserEdit } from "pages/users/edit";
import { TenantList } from "pages/tenants/list";
import { TenantCreate } from "pages/tenants/create";
import { SettingsPage } from "pages/settings";
import { TenantEdit } from "pages/tenants/edit";
import { ProductItemList } from "pages/productItems/list";
import DataGridView from "pages/dataGrid/list";
import PriceCheckView from "pages/priceCheck/list";
import { liveProvider } from "live-provider/liveprovider";
import { themeConfig } from "components/config/antd";
import { CustomLayout } from "components/layout";
import { ProductItemDetailsEdit } from "pages/products/edit/editDetails";
import { ProductItemRelationEdit } from "pages/products/edit/editRelation";
import { ProductItemMediaEdit } from "pages/products/edit/editMedia";
import { ProductItemSpecificationEdit } from "pages/products/edit/editSpecification";
import { ProductList } from "pages/products/list";
import { css, Global } from '@emotion/react';
import './fonts/font.css';
import { LabelList } from "pages/labels/list";
import FileGrid from "pages/files/list";
import { ChangeLogPage } from "pages/changelog";
import { WorkTaskPage } from "pages/workTask/list";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { PromoCardsList } from "pages/ecommerce/promoCards/list";
import { BundleProductsList } from "pages/bundleProducts/list";
import { FeedbackList } from "pages/ecommerce/feedback/list";
import { SynonymListPage } from "pages/ecommerce/synonyms/list";

const globalStyles = css`
  body {
    .ant-layout-sider {
      /* background-color: #305671 !important; */
      background-color: #17496c !important;
    }
    .ant-layout {
      background-color: #f5f5f8 !important;
    }
  }
`;

const DEFAULT_ICON_SIZE = "1.2rem";
const DEFAULT_ICON_STROKE_WIDTH = "2px";
const DEFAULT_ICON_MARGIN_CORRECTION = "-2px";

interface PageTitleHandlerProps {
    resource?: IResourceItem;
    action?: Action;
    params?: Record<string, string | undefined>;
    pathname?: string;
    autoGeneratedTitle: string;
}

const customTitleHandler = ({ resource, action, params } : PageTitleHandlerProps) => {
    let title = "VB PIM";

    if(resource && action) {
        title = `${resource?.meta?.label} | VB PIM`;
    }

    return title;
};

const App: React.FC = () => {
  const { t, i18n } = useTranslation();
  const i18nProvider: I18nProvider = {
    translate: (key: string, options?: any): string => t(key, options).toString(),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  const queryClient = new QueryClient();
  
    return (
      <>
      <Global styles={globalStyles} />
      <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <ConfigProvider locale={sv_SE} theme={themeConfig}>
          <Refine
            dataProvider={dataProvider(
              process.env.REACT_APP_API_URL ?? "http://localhost/v1"
            )}
            liveProvider={liveProvider()}
            i18nProvider={i18nProvider}
            accessControlProvider={customAccessControlProvider}
            authProvider={authProvider}
            routerProvider={routerProvider}
            resources={[
              {
                name: "dashboard",
                list: "/",
                meta: {
                  label: "Dashboard",
                  icon: <Gauge size={DEFAULT_ICON_SIZE} strokeWidth={DEFAULT_ICON_STROKE_WIDTH} style={{marginTop: DEFAULT_ICON_MARGIN_CORRECTION}}/>,
                },
              },
              {
                name: USERS_RES_ID,
                list: "/users",
                create: "/users/create",
                edit: "/users/edit/:id",
                // show: "/users/show/:id",
                meta: {
                  label: "Användare",
                  icon: <UsersRound size={DEFAULT_ICON_SIZE} strokeWidth={DEFAULT_ICON_STROKE_WIDTH} style={{marginTop: DEFAULT_ICON_MARGIN_CORRECTION}}/>,
                },
              },
              {
                name: TENANT_RES_ID,
                list: "/tenants",
                create: "/tenants/create",
                edit: "/tenants/edit/:id",
                show: "/tenants/show/:id",
                meta: {
                  label: "Organisationer",
                  icon: <Landmark size={DEFAULT_ICON_SIZE} strokeWidth={DEFAULT_ICON_STROKE_WIDTH} style={{marginTop: DEFAULT_ICON_MARGIN_CORRECTION}}/>,
                },
              },
              {
                name: "catalog",
                meta: {
                  label: "Katalog",
                  icon: <Layers size={DEFAULT_ICON_SIZE} strokeWidth={DEFAULT_ICON_STROKE_WIDTH} style={{marginTop: DEFAULT_ICON_MARGIN_CORRECTION}}/>,
                }
              },
              {
                name: "ecommerce",
                meta: {
                  label: "E-handel",
                  icon: <Store size={DEFAULT_ICON_SIZE} strokeWidth={DEFAULT_ICON_STROKE_WIDTH} style={{marginTop: DEFAULT_ICON_MARGIN_CORRECTION}}/>,
                }
              },
              {
                name: PRODUCT_RES_ID,
                list: "/products",
                create: "/products/create",
                edit: "/products/edit/:id",
                show: "/products/show/:id",
                meta: {
                  parent: "catalog",
                  label: "Produkter",
                  icon: <Box size={DEFAULT_ICON_SIZE} strokeWidth={DEFAULT_ICON_STROKE_WIDTH} style={{marginTop: DEFAULT_ICON_MARGIN_CORRECTION}}/>,
                },
              },
              {
                name: PRODUCT_ITEM_RES_ID,
                list: "/productItems",
                create: "/productItems/create",
                edit: "/productItems/edit/:id",
                show: "/productItems/show/:id",
                meta: {
                  parent: "catalog",
                  label: "Varianter",
                  icon: <List size={DEFAULT_ICON_SIZE} strokeWidth={DEFAULT_ICON_STROKE_WIDTH} style={{marginTop: DEFAULT_ICON_MARGIN_CORRECTION}}/>,
                },
              },
              {
                name: SPECIFICATION_LABEL_RES_ID,
                list: "/labels",
                create: "/labels/create",
                edit: "/labels/edit/:id",
                show: "/labels/show/:id",
                meta: {
                  parent: "catalog",
                  label: "Etiketter",
                  icon: <Tags size={DEFAULT_ICON_SIZE} strokeWidth={DEFAULT_ICON_STROKE_WIDTH} style={{marginTop: DEFAULT_ICON_MARGIN_CORRECTION}}/>,
                },
              },
              {
                name: FILE_RES_ID,
                list: "/files",
                create: "/files/create",
                edit: "/files/edit/:id",
                show: "/files/show/:id",
                meta: {
                  label: "Filhanterare",
                  icon: <FileSearch size={DEFAULT_ICON_SIZE} strokeWidth={DEFAULT_ICON_STROKE_WIDTH} style={{marginTop: DEFAULT_ICON_MARGIN_CORRECTION}}/>,
                },
              },
              {
                name: "data-grid",
                list: "/data-grid",
                // create: "/files/create",
                // edit: "/files/edit/:id",
                // show: "/files/show/:id",
                meta: {
                  label: "Multiredigering",
                  icon: <Rocket size={DEFAULT_ICON_SIZE} strokeWidth={DEFAULT_ICON_STROKE_WIDTH} style={{marginTop: DEFAULT_ICON_MARGIN_CORRECTION}}/>,
                },
              },
              {
                name: "price-check",
                list: "/price-check",
                meta: {
                  label: "Priskoll",
                  icon: <CircleDollarSign size={DEFAULT_ICON_SIZE} strokeWidth={DEFAULT_ICON_STROKE_WIDTH} style={{marginTop: DEFAULT_ICON_MARGIN_CORRECTION}}/>,
                },
              },
              {
                name: "worktasks",
                list: "/worktasks",
                meta: {
                  label: "Arbetslistor",
                  icon: <ListCheck size={DEFAULT_ICON_SIZE} strokeWidth={DEFAULT_ICON_STROKE_WIDTH} style={{marginTop: DEFAULT_ICON_MARGIN_CORRECTION}}/>,
                },
              },
              {
                name: PROMO_CARDS_RES_ID,
                list: "/ecommerce/promocards",
                meta: {
                  parent: "ecommerce",
                  label: "Promokort",
                  icon: <Megaphone size={DEFAULT_ICON_SIZE} strokeWidth={DEFAULT_ICON_STROKE_WIDTH} style={{marginTop: DEFAULT_ICON_MARGIN_CORRECTION}}/>
                }
              },
              {
                name: FEEDBACK_RES_ID,
                list: "/ecommerce/feedback",
                meta: {
                  parent: "ecommerce",
                  label: "Feedback",
                  icon: <MessageSquare size={DEFAULT_ICON_SIZE} strokeWidth={DEFAULT_ICON_STROKE_WIDTH} style={{marginTop: DEFAULT_ICON_MARGIN_CORRECTION}}/>
                }
              },
              {
                name: SYNONYM_RES_ID,
                list: "/ecommerce/synonyms",
                meta: {
                  parent: "ecommerce",
                  label: "Synonymer",
                  icon: <FileSearch size={DEFAULT_ICON_SIZE} strokeWidth={DEFAULT_ICON_STROKE_WIDTH} style={{marginTop: DEFAULT_ICON_MARGIN_CORRECTION}}/>
                }
              },
              {
                name: "bundleProducts",
                list: "/bundleProducts",
                create: "/bundleProducts/create",
                edit: "/bundleProducts/edit/:id",
                show: "/bundleProducts/show/:id",
                meta: {
                  parent: "catalog",
                  label: "Paketprodukter",
                  icon: <PackageSearch size={DEFAULT_ICON_SIZE} strokeWidth={DEFAULT_ICON_STROKE_WIDTH} style={{marginTop: DEFAULT_ICON_MARGIN_CORRECTION}}/>,
                },
              }
            ]}
            notificationProvider={notificationProvider}
            options={{
              liveMode: "auto",
              syncWithLocation: true,
              warnWhenUnsavedChanges: true,
            }}
          >
            <Routes>
              <Route
                element={
                  <Authenticated
                    key="auth-pages"
                    fallback={<CatchAllNavigate to="/login" />}
                  >
                    <CustomLayout
                      Header={() => {
                        return <Header></Header>;
                      }}
                    >
                      <Outlet />
                    </CustomLayout>
                  </Authenticated>
                }
              >
                {/* <Route index element={<DashboardPage />} /> */}

                <Route path="/">
                  <Route
                    index
                    element={
                      <CanAccess
                        fallback={<h1>Du saknar access till denna sida</h1>}
                      >
                        <DashboardPage />
                      </CanAccess>
                    }
                  />
                </Route>

                <Route path="/data-grid">
                  <Route
                    index
                    element={
                      <CanAccess
                        fallback={<h1>Du saknar access till denna sida</h1>}
                      >
                        <DataGridView />
                      </CanAccess>
                    }
                  />
                </Route>

                <Route path="/price-check">
                  <Route
                    index
                    element={
                      <CanAccess
                        resource={PRICE_CHECK_RES_ID}
                        action="list"
                        fallback={<h1>Du saknar access till denna sida</h1>}
                      >
                        <PriceCheckView />
                      </CanAccess>
                    }
                  />
                </Route>

                <Route path="/worktasks">
                  <Route
                    index
                    element={
                      <CanAccess
                        fallback={<h1>Du saknar access till denna sida</h1>}
                      >
                        <WorkTaskPage />
                      </CanAccess>
                    }
                  />
                </Route>

                <Route path="/settings">
                  <Route
                    index
                    element={
                      <CanAccess
                        fallback={<h1>Du saknar access till denna sida</h1>}
                      >
                        <SettingsPage />
                      </CanAccess>
                    }
                  />
                </Route>

                <Route path="/changelog">
                  <Route
                    index
                    element={
                      <CanAccess
                        fallback={<h1>Du saknar access till denna sida</h1>}
                      >
                        <ChangeLogPage />
                      </CanAccess>
                    }
                  />
                </Route>

                <Route path="/users">
                  <Route
                    index
                    element={
                      <CanAccess
                        resource={USERS_RES_ID}
                        action="list"
                        fallback={<h1>Du saknar access till denna sida</h1>}
                      >
                        <UsersList />
                      </CanAccess>
                    }
                  />
                  <Route
                    path="create"
                    element={
                      <CanAccess
                        resource={USERS_RES_ID}
                        action="create"
                        fallback={<h1>Du saknar access till denna sida</h1>}
                      >
                        <UserCreate />
                      </CanAccess>
                    }
                  />
                  <Route
                    path="edit/:id"
                    element={
                      <CanAccess
                        resource={USERS_RES_ID}
                        action="edit"
                        fallback={<h1>Du saknar access till denna sida</h1>}
                      >
                        <UserEdit />
                      </CanAccess>
                    }
                  />
                </Route>

                <Route path="/files">
                  <Route
                    index
                    element={
                      <CanAccess
                        resource={FILE_RES_ID}
                        action="list"
                        fallback={<h1>Du saknar access till denna sida</h1>}
                      >
                        <FileGrid />
                      </CanAccess>
                    }
                  />
                </Route>

                <Route path="/tenants">
                  <Route
                    index
                    element={
                      <CanAccess
                        resource={TENANT_RES_ID}
                        action="list"
                        fallback={<h1>Du saknar access till denna sida</h1>}
                      >
                        <TenantList />
                      </CanAccess>
                    }
                  />
                  <Route
                    path="create"
                    element={
                      <CanAccess
                        resource={TENANT_RES_ID}
                        action="create"
                        fallback={<h1>Du saknar access till denna sida</h1>}
                      >
                        <TenantCreate />
                      </CanAccess>
                    }
                  />
                  <Route
                    path="edit/:id"
                    element={
                      <CanAccess
                        resource={TENANT_RES_ID}
                        action="edit"
                        fallback={<h1>Du saknar access till denna sida</h1>}
                      >
                        <TenantEdit />
                      </CanAccess>
                    }
                  />
                </Route>

                <Route path="/productItems">
                  <Route
                    index
                    element={
                      <CanAccess
                        resource={PRODUCT_ITEM_RES_ID}
                        action="list"
                        fallback={<h1>Du saknar access till denna sida</h1>}
                      >
                        <ProductItemList record={undefined}/>
                      </CanAccess>
                    }
                  />
                  <Route
                    path="edit/:id"
                    element={
                      <CanAccess
                        resource={USERS_RES_ID}
                        action="edit"
                        fallback={<h1>Du saknar access till denna sida</h1>}
                      >
                        <ProductItemDetailsEdit />
                      </CanAccess>
                    }
                  />
                </Route>

                <Route path="/labels">
                  <Route
                    index
                    element={
                      <CanAccess
                        resource={SPECIFICATION_LABEL_RES_ID}
                        action="list"
                        fallback={<h1>Du saknar access till denna sida</h1>}
                      >
                        <LabelList />
                      </CanAccess>
                    }
                  />
                </Route>

                <Route path="/products">
                  <Route
                    index
                    element={
                      <CanAccess
                        resource={PRODUCT_RES_ID}
                        action="list"
                        fallback={<h1>Du saknar access till denna sida</h1>}
                      >
                        <ProductList />
                      </CanAccess>
                    }
                  />
                  <Route
                    path="edit/specification/:id"
                    element={
                      <CanAccess
                        resource={PRODUCT_RES_ID}
                        action="edit"
                        fallback={<h1>Du saknar access till denna sida</h1>}
                      >
                        <ProductItemSpecificationEdit />
                      </CanAccess>
                    }
                  />
                  <Route
                    path="edit/:id"
                    element={
                      <CanAccess
                        resource={PRODUCT_RES_ID}
                        action="edit"
                        fallback={<h1>Du saknar access till denna sida</h1>}
                      >
                        <ProductItemDetailsEdit />
                      </CanAccess>
                    }
                  />
                  <Route
                    path="edit/media/:id"
                    element={
                      <CanAccess
                        resource={PRODUCT_RES_ID}
                        action="edit"
                        fallback={<h1>Du saknar access till denna sida</h1>}
                      >
                        <ProductItemMediaEdit />
                      </CanAccess>
                    }
                  />
                  <Route
                    path="edit/relations/:id"
                    element={
                      <CanAccess
                        resource={PRODUCT_RES_ID}
                        action="edit"
                        fallback={<h1>Du saknar access till denna sida</h1>}
                      >
                        <ProductItemRelationEdit />
                      </CanAccess>
                    }
                  />
                </Route>

                <Route path="/ecommerce">
                  <Route path="promoCards">
                    <Route
                      index
                      element={
                        <CanAccess
                          resource={PROMO_CARDS_RES_ID}
                          action="list"
                          fallback={<h1>Du saknar access till denna sida</h1>}
                        >
                          <PromoCardsList />
                        </CanAccess>
                      }
                    />
                  </Route>
                  <Route path="feedback">
                    <Route
                      index
                      element={
                        <CanAccess
                          resource={FEEDBACK_RES_ID}
                          action="list"
                          fallback={<h1>Du saknar access till denna sida</h1>}
                        >
                          <FeedbackList />
                        </CanAccess>
                      }
                    />
                  </Route>
                  <Route path="synonyms">
                    <Route
                      index
                      element={
                        <CanAccess
                          resource={SYNONYM_RES_ID}
                          action="list"
                          fallback={<h1>Du saknar access till denna sida</h1>}
                        >
                          <SynonymListPage />
                        </CanAccess>
                      }
                    />
                  </Route>
                </Route>

                <Route path="/bundleProducts">
                  <Route
                    index
                    element={
                      <CanAccess
                        resource="bundleProducts"
                        action="list"
                        fallback={<h1>Du saknar access till denna sida</h1>}
                      >
                        <BundleProductsList />
                      </CanAccess>
                    }
                  />
                </Route>
              </Route>

              <Route
                element={
                  <Authenticated key="dashboard" fallback={<Outlet />}>
                    <NavigateToResource resource="dashboard" />
                  </Authenticated>
                }
              >
                <Route path="/login" element={<Login />} />
                <Route
                  path="/forgot-password"
                  element={<AuthPage type="forgotPassword" />}
                />
                <Route
                  path="/update-password"
                  element={<AuthPage type="updatePassword" />}
                />
              </Route>

              <Route
                element={
                  <Authenticated key="catch-all">
                    <ThemedLayoutV2>
                      <Outlet />
                    </ThemedLayoutV2>
                  </Authenticated>
                }
              >
                <Route path="*" element={<ErrorComponent />} />
              </Route>
            </Routes>
            <UnsavedChangesNotifier />
            <DocumentTitleHandler handler={customTitleHandler} />
          </Refine>
        </ConfigProvider>
      </BrowserRouter>
      </QueryClientProvider>
      </>
    );
};

export default App;
