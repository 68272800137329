import { useEffect, useRef, useState } from 'react';
import { SynonymDto, CreateSynonymRequest, UpdateSynonymRequest } from "interfaces/apiTypes";
import { useOpenApiClient } from "./base/useOpenApiClient";
import { ErrorResponse } from 'interfaces/baseTypes';
import { components } from './base/schema';
import { useLocaleStore } from 'stores/localeStore';

const useSynonymOpenApi = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<ErrorResponse | null>(null);
  const { client, getClient } = useOpenApiClient();
  const { activeLanguage } = useLocaleStore();
  const clientRef = useRef(client);

  useEffect(() => {
    clientRef.current = client;
  }, [client]);

  async function getSynonyms(): Promise<SynonymDto[]> {
    setLoading(true);
    setErrorMessage(null);
    try {
        if(!clientRef.current){
            clientRef.current = getClient();
        }
        
        if (!clientRef.current) {
            throw new Error('API client is not initialized');
        }
        
        const response = await clientRef.current?.GET('/api/synonyms', {
            headers: {
                'Lang': activeLanguage?.locale ?? "sv"
            }
        });

        const error = response?.error as unknown as ErrorResponse;
        if (error) {
            setErrorMessage(error);
            return [];
        }

        return response?.data as SynonymDto[];
    } catch (e: any) {
        setErrorMessage(e.message || 'An error occurred');
        return [];
    } finally {
        setLoading(false);
    }
  }

  const createSynonym = async (request: CreateSynonymRequest): Promise<SynonymDto | undefined> => {
    setLoading(true);
    setErrorMessage(null);
    try {
      if (!clientRef.current) {
        clientRef.current = getClient();
      }
      const response = await clientRef.current?.POST("/api/synonyms", {
        body: request,
        headers: {
          'Content-Type': 'application/json',
          'Lang': activeLanguage?.locale ?? "sv"
        }
      });

      const error = response?.error as unknown as ErrorResponse;
      if (error) {
        setErrorMessage(error);
        return undefined;
      }

      return response?.data as SynonymDto;
    } catch (e: any) {
      setErrorMessage(e.message || 'An error occurred');
      return undefined;
    } finally {
      setLoading(false);
    }
  };

  const updateSynonym = async (id: number, request: UpdateSynonymRequest): Promise<SynonymDto | undefined> => {
    setLoading(true);
    setErrorMessage(null);
    try {
      if (!clientRef.current) {
        clientRef.current = getClient();
      }
      const response = await clientRef.current?.PUT('/api/synonyms/{id}', {
        params: { path: { id } },
        body: request,
        headers: {
          'Content-Type': 'application/json',
          'Lang': activeLanguage?.locale ?? "sv"
        }
      });

      const error = response?.error as unknown as ErrorResponse;
      if (error) {
        setErrorMessage(error);
        return undefined;
      }

      return response?.data as SynonymDto;
    } catch (e: any) {
      setErrorMessage(e.message || 'An error occurred');
      return undefined;
    } finally {
      setLoading(false);
    }
  };

  const deleteSynonym = async (id: number): Promise<void> => {
    setLoading(true);
    setErrorMessage(null);
    try {
      if (!clientRef.current) {
        clientRef.current = getClient();
      }
      await clientRef.current?.DELETE('/api/synonyms/{id}', {
        params: { path: { id } },
        headers: {
          'Lang': activeLanguage?.locale ?? "sv"
        }
      });
    } catch (e: any) {
      setErrorMessage(e.message || 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  return {
    getSynonyms,
    createSynonym,
    updateSynonym,
    deleteSynonym,
    loading,
    errorMessage
  };
};

export default useSynonymOpenApi; 