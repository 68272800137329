import ProductCategoriesSelect from "components/common/productCategories/productCategoriesSelect";
import { useCallback, useState } from "react";
import { Modal, Typography } from "antd";
import useMetadataStore from "stores/metadataStore";
import { ICategory } from "interfaces";
import styled from "@emotion/styled";

const ModalWrapper = styled("div")`
  .ant-select {
    width: 100%;
  }
  .search-label {
    padding-bottom: 0.1rem;
    display: block;
  }
`;

interface CategoriesModalGridProps {
  editorProps: any;
}


const noDataCategory: ICategory = {
  id: -1,
  name: "",
  subCategories: [],
  hasSubcategories: false,
  externalId: -1,
  slug: "",
  isHidden: false,
};

const CategoriesModalGrid = ({
  editorProps,
}: CategoriesModalGridProps) => {
  const [selectedCategories, setSelecteCategories] = useState<(ICategory | undefined)[]>([]);
  const { getCategoryById } = useMetadataStore();

  const onCategoryChange = useCallback((categoryIds: number[]) => {
    const categories = categoryIds?.map(id => getCategoryById(id) ?? {...noDataCategory, id})
    setSelecteCategories(categories);
  }, []);

  const onClose = () => {
    editorProps.cell.stopEdit();
  };

  const onSave = async () => {
    await editorProps.onChange([...selectedCategories]);
    editorProps.onComplete();
  };

  return (
    <>
      <Modal
        open={editorProps.cell.isInEdit()}
        okText={"Spara"}
        width={1000}
        onOk={onSave}
        onCancel={onClose}
      >
        <ModalWrapper>
        <h3 style={{ fontSize: "18px" }}>Hantera kategorier</h3>
        <Typography.Text className="search-label">Lägg till kategori</Typography.Text>
        <ProductCategoriesSelect
          categories={[...(editorProps?.value ?? [])]}
          onChange={onCategoryChange}
          formLoading={false}
        ></ProductCategoriesSelect>
        </ModalWrapper>
      </Modal>
    </>
  );
};

export default CategoriesModalGrid;