import { BaseKey } from '@refinedev/core';
import type { components } from '../apiClients/base/schema';
import { Base, CategoryInstance, MediaAssetInstance } from './baseTypes';

type DeepRequired<T> = T extends object ? {
    [K in keyof T]-?: T[K] extends (infer U)[] 
      ? DeepRequired<U>[] 
      : DeepRequired<T[K]>
  } : T;

export type EntityMetaData = components["schemas"]["EntityMetaDataResponse"];
export type EntityType = components["schemas"]["MetaDataType"];
export type FieldMetaData = components["schemas"]["FieldMetaData"];
export type EnumInfo = components["schemas"]["EnumMetaData"] & {
    translationKey?: string;
    enumDisplayValue?: string;
};

export type PatchRequest = components["schemas"]["PatchRequest"];
export type PatchRequestItem = components["schemas"]["DtoValuePatch"]

export type PriceTracker = DeepRequired<components["schemas"]["PriceTrackerDto"]> & Base;
export type PriceTrackerItem = DeepRequired<components["schemas"]["PriceTrackerItemDto"]> & Base;
export type ProductTranslation = DeepRequired<components["schemas"]["ProductTranslationDto"]> & Base;
export type SalesChannel = DeepRequired<components["schemas"]["SalesChannelDto"]> & Base;
export type Completeness = DeepRequired<components["schemas"]["CompletenessDto"]> & Base;
export type WorkTask = DeepRequired<components["schemas"]["WorkTaskDto"]> & Base;
export type WorkTaskType = DeepRequired<components["schemas"]["TaskType"]>;
export type MediaAsset = DeepRequired<components["schemas"]["MediaAssetDto"]> & Base;
export type MediaAssetRelation = DeepRequired<components["schemas"]["MediaAssetRelationDto"]> & Base;
export type MediaAssetResolution = DeepRequired<components["schemas"]["MediaAssetResolutionDto"]> & Base;
export type ProductItem = DeepRequired<components["schemas"]["ProductItemDto"]> & Base;
export type SalesChannelDto = DeepRequired<components["schemas"]["SalesChannelDto"]> & Base;
export type Category = DeepRequired<components["schemas"]["CategoryDto"]> & Base;
export type ProductCategory = DeepRequired<components["schemas"]["ProductCategoryDto"]> & Base;
export type LocalizedDto = DeepRequired<components["schemas"]["LocalizedDto"]> & Base;

export type ProductStatus = components["schemas"]["ProductStatus"];

export type ProductRuleDto = components["schemas"]["ProductRuleDto"];
export type ProductRuleDtoPaginationResult = components["schemas"]["ProductRuleDtoPaginationResult"];
export type ProductRuleCreateRequest = components["schemas"]["BoostRuleCreateRequest"];
export type ProductRuleUpdateRequest = components["schemas"]["BoostRuleUpdateRequest"];

export type ProductScoreDto = components["schemas"]["ProductScoreDto"];
export type ProductScoreDtoPaginationResult = components["schemas"]["ProductScoreDtoPaginationResult"];

export type SynonymDto = components["schemas"]["SynonymDto"];
export type CreateSynonymRequest = components["schemas"]["CreateSynonymRequest"];
export type UpdateSynonymRequest = components["schemas"]["UpdateSynonymRequest"];


export enum TaskTypeEnum {
    Product = 0,
    PriceTracker = 1,
}

export enum RuleTypeEnum {
    Brand = 0,
    Category = 1,
    CategoryCampaign = 2,
    IndividualProduct = 3,
    AllCampaignProducts = 4,
    AllNewsProducts = 5
}

export enum ImageSizeEnum {
    Original = 0,
    Small = 1,
    Medium = 2,
}

export type Product = DeepRequired<components["schemas"]["ProductDto"]> & {
    getName(): string;
    getSecondaryName(): string;
    getBrandName(): string;
    getDescription(): string;
    getPrice(): number;
    getImageUrl(): string;
    getImages(): MediaAssetInstance[];
    getSafetySheets(): MediaAssetInstance[];
    getManuals(): MediaAssetInstance[];
    getDimensionalSheets(): MediaAssetInstance[];
    getEan(): string;
    getSalePrice(): number;
    getItemNumber(): string;
    getLongDescriptionSupplier(): string;
    getItemGroupName(): string;
    getSaleChannels(): SalesChannel[];
    getCompleteness(): Completeness | undefined,
    getCategories(): CategoryInstance[]
};

export function enhanceProduct(product: DeepRequired<components["schemas"]["ProductDto"]>, languageId: number): Product {
    return {
        ...product,
        getName: function() {
            return this.translations?.find(t => t.languageId === languageId)?.name ?? this.translations?.[0]?.name ?? 'Namn Saknas';
        },
        getSecondaryName: function() {
            return this.translations?.find(t => t.languageId === languageId)?.secondaryName ?? this.translations?.[0]?.secondaryName ?? '';
        },
        getBrandName: function() {
            return this.brand?.name ?? '';
        },
        getDescription: function() {
            return this.translations?.find(t => t.languageId === languageId)?.description ?? this.translations?.[0]?.description ?? '';
        },
        getPrice: function() {
            return this.productItems?.find(item => item.itemNo === this.mainItemNo)?.translations?.find(t => t.languageId === languageId)?.salePriceInVat ?? 0;
        },
        getImageUrl: function() {
            return this.mediaAssets?.sort((a, b) => a.order - b.order)[0]?.mediaAsset?.resolutions?.find(r => r.size === 1)?.url ?? this.mediaAssets?.sort((a, b) => a.order - b.order)[0]?.mediaAsset?.url ?? '';
        },
        getEan: function() {
            return this.productItems?.find(item => item.itemNo === this.mainItemNo)?.ean ?? '';
        },
        getSalePrice: function() {
            return this.productItems?.find(item => item.itemNo === this.mainItemNo)?.translations?.find(t => t.languageId === languageId)?.salePriceInVat ?? 0;
        },
        getItemNumber: function() {
            return this.productItems?.find(item => item.itemNo === this.mainItemNo)?.itemNo ?? '';
        },
        getLongDescriptionSupplier: function() {
            return this.translations?.find(t => t.languageId === languageId)?.longDescriptionSupplier ?? this.translations?.[0]?.longDescriptionSupplier ?? '';
        },
        getCompleteness: function() {
            return this.translations?.find(t => t.languageId === languageId)?.completeness ?? undefined;
        },
        getItemGroupName: function() {
            return this.productItems?.find(item => item.itemNo === this.mainItemNo)?.group?.name ?? '';
        },
        getSaleChannels: function() {
            return this.salesChannels?.map(sc => sc.salesChannel) ?? [];
        },
        getImages: function() {
            return this.mediaAssets?.sort((a, b) => a.order - b.order).filter(ma => ma.relationType === 'ProductImage').map(ma => new MediaAssetInstance(ma)) ?? [];
        },
        getSafetySheets: function() {
            return this.mediaAssets?.sort((a, b) => a.order - b.order).filter(ma => ma.relationType === 'SafetySheet').map(ma => new MediaAssetInstance(ma)) ?? [];
        },
        getManuals: function() {
            return this.mediaAssets?.sort((a, b) => a.order - b.order).filter(ma => ma.relationType === 'Manual').map(ma => new MediaAssetInstance(ma)) ?? [];
        },
        getDimensionalSheets: function() {
            return this.mediaAssets?.sort((a, b) => a.order - b.order).filter(ma => ma.relationType === 'DimensionalSheet').map(ma => new MediaAssetInstance(ma)) ?? [];
        },
        getCategories: function() {
            return this.categories?.map(c => new CategoryInstance(c.category, languageId)) ?? [];
        }
    };
}
export interface ResponseWrapperMany<T> {
    metaData: EntityMetaData;
    items: T[];
    totalCount: number;
    hasNextPage: boolean;
}

class ClientNotReadyError extends Error {
    code: string;

    constructor(message: string, code: string) {
        super(message);
        this.code = code;
        this.name = this.constructor.name; // Set the error name to the class name
    }
}
export { ClientNotReadyError };

export function getRuleTypeDisplayName(number: number): string {
    const ruleType = Number(number) as RuleTypeEnum;
    switch (ruleType) {
        case RuleTypeEnum.Brand:
            return "Produkter med varumärke";
        case RuleTypeEnum.Category:
            return "Produkter i kategori";
        case RuleTypeEnum.CategoryCampaign:
            return "Produkter i kategori med kampanj";
        case RuleTypeEnum.IndividualProduct:
            return "Produkter";
        case RuleTypeEnum.AllCampaignProducts:
            return "Alla kampanjprodukter";
        case RuleTypeEnum.AllNewsProducts:
            return "Alla nya produkter";
        default:
            return "Okänd";
    }
}
